



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background: url(../src/components/img/budynek-foto-2.webp);
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .navbar{
  align-self: start;
} */
.navbaritem{
  justify-content: space-evenly;
  color: rgb(255, 255, 255);
  background-color: red;
  
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: 100%;
  
}

.navbaritem:hover{
  /* font-size: 30px; */
  color: rgb(0, 0, 0);
}

/* .appbg{
background-color: rgba(255, 255, 255, 0.5);
} */

.video-wrapper {
 
  width: 100%;
  min-height: 65vh;
  
 }
 .change-my-color {
  fill: red;
}
/* .btnhome0{
  position: fixed;
  right:2vw;
  top: 75vh;
  border-radius: 20px;
  width: 10vw;
}
.btnhome1{
  position: fixed;
  right:2vw;
  top: 75vh;
  border-radius: 20px;
  width: 10vw;
}
.btnhome2{
  position: fixed;
  right:2vw;
  top: 80vh;
  border-radius: 20px;
  width: 10vw;
}
.btnhome3{
  position: fixed;
  right:2vw;
  top: 85vh;
  border-radius: 20px;
  width: 10vw;
}
.btnhome4{
  position: fixed;
  right:2vw;
  top: 90vh;
  border-radius: 20px;
  width: 10vw;
} */

.homeBar{
  position: fixed;
  height:5vw;
  top: 95vh;
  border-radius: 20px;
  width: 100vw;
}


.modalbg{
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.2);
}

.car{
  min-height: 30vw;
}

.tabela{
  min-height: 5vh !important;
  
}
/* .modalid{
  min-height: 80vh;
} */
.standard{
  background-color: #050505 !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -3;
}

.lokalebg{
  background-color: white !important;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -3;
}
@media only screen and (max-width: 600px) {
  .textMobile{
    font-size: small;
  }
  .tabela{
    min-height: 5vh !important;
    font-size: x-small;
  }
}

.card{
  border-radius: 20px;
}

.shadow{
  box-shadow: 4px 4px 100px 5px #050505 !important;
  
  border-radius: 10px !important;
}

.home{
  min-height: 100vh !important;
}
.navCard{
  border-radius: 20px !important;
  /* width: 80vw; */
}

.pawla12{
  min-height: 100vh !important;
  min-width: 100vw !important;
  z-index: 6 !important;
  position: absolute !important;
  
}